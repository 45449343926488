<template>
  <div class="map">
    <div id="googleMap"></div>
    <Message v-if="message" :type="message.type" :message="message.message" />
    <div class="contact-form">
       <ContactForm />
    </div>
  </div>
</template>

<script>
/*global google*/
import { mapState } from 'vuex'
import ContactForm from '@/components/molecules/ContactForm'
import Message from '@/components/molecules/Message'
export default {
  components: {
    ContactForm,
    Message
  },
  data () {
    return {
      Lat: 50.99059408734266,
      Lng: -113.97811483115278
    }
  },
  computed: {
    ...mapState(['message'])
  },
  mounted() {
    this.initMap()
  },
  methods: {
    initMap() {
      let mapProp= {
        center:new google.maps.LatLng(this.Lat, this.Lng),
        zoom: 17,
      }
      let map = new google.maps.Map(document.getElementById("googleMap"),mapProp)
      const marker = new google.maps.Marker({ position: { lat: this.Lat, lng: this.Lng }, map: map})
      const infowindow = new google.maps.InfoWindow({
        content: "<p>Marker Location:" + marker.getPosition() + "</p>",
      })
      google.maps.event.addListener(marker, "click", () => {
        infowindow.open(map, marker)
      })
    }
  }
}
</script>

<style lang="sass" scoped>
#googleMap
  width: 100%
  height: 600px
  @media screen and (max-width: $md-breakpoint)
    height: 300px
.map
  position: relative
  .contact-form
    width: 50%
    position: absolute
    top: 65%
    right: 100px
    @media screen and (max-width: $lg-breakpoint)
      width: 100%
      position: unset
</style>