<template>
  <div class="details-wrap">
    <div class="header-block">
      <div class="email-icon">
        <img src="@/assets/icons/email-1.svg" name="icon" />
      </div>
      <h3>Send us an message</h3>
    </div>
    <div class="input-wrapper contact">
      <div class="input-container noIcon">
        <input class="input-field" type="text" placeholder="Your name" v-model="contact.name">
      </div>
      <div class="input-container noIcon">
        <input class="input-field" type="text" placeholder="Your email" v-model="contact.email">
      </div>
    </div>
    <div class="input-wrapper textArea">
      <div class="input-container noIcon">
        <textarea class="input-field" type="text" placeholder="Your message..." v-model="contact.message"/>
      </div>
      <div class="input-wrapper-button">
        <button class="black pointer" @click="onSend">SEND</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  data() {
    return {
      contact: {
        name: null,
        email: null,
        message: null
      }
    }
  },
  computed: {
    ...mapState(['message'])
  },
  watch: {
    message: function () {
      if (this.message) {
        if (this.message.type === 'success') {
          Object.keys(this.contact).forEach((key) => {
            this.contact[key] = null
          })
        }
      }
    }
  },
  methods: {
    onSend() {
      if (this.contact['name'] && this.contact['email'] && this.contact['message']) {
        this.$store.dispatch('contact/sendQuery', this.contact)
      } else {
        this.$store.commit('setMessage', { type: 'error', 'message': "You cannot send empty form!"})
      }
    }
  }
}
</script>

<style lang="sass" scoped>
@import '../../sass/login.sass'
%placeholder-props
  color: #a7a7a7
  font-size: 17px
  font-weight: 500 
.details-wrap
  padding: 3.8em 4.4em 6.2em 4.4em
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.33)
  background: $white
  @media( max-width: $phone-breakpoint)
    padding: 1em
  .header-block
    .email-icon
      img
        width: 60px
    h3
      margin-bottom: 50px
      font-weight: 500
      font-size: 1.7em
  .input-wrapper
    &.contact
      flex-direction: row
      .input-container
        flex: 1
        margin-bottom: 1em
        &:first-child
          margin-right: 0.3em
        &:nth-child(2)
          margin-left: 0.3em
        ::placeholder
          @extend %placeholder-props
        .input-field
          height: 100%
    &.textArea
      .input-container, .input-container > textarea
        height: 15.4em
        resize: none
        ::placeholder
          @extend %placeholder-props
  .input-wrapper-button
    button
      &.black
        border: none
        font-size: 16px
        font-weight: 300
        height: 55px
</style>
