<template>
  <div class="contact-wrapper">
    <div class="contact">
      <div class="contact__heading">
        <h3 class="heading">Contact Details</h3>
      </div>
      <div class="contact__description gray">
        There is no place like home. To help you make your perfect, our stores are open, we're delivering as normal, our online store is available 24/7, and our customer servcie team is ready to help you via phone and Live Chat.
      </div>
      <div v-for="(item, index) in list" :key="index" class="contact__list">
        <div class="contact__list--box">
          <img :src="require(`@/assets/icons/${item.icon}`)" name="icon" />
        </div>
         <div class="contact__list--item">
          <h4 :class="{ 'bold': item.id != 4}">{{ item.heading }}</h4>
          <p :class="{'gray': item.id != 4}">{{ item.description}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      list: [
        {
          id: 1,
          icon: 'placeholder.svg',
          heading: 'Minhas Furniture House LTD',
          description: '3916 72  Ave SE, Calgory AB. T2C 2E2' 
        },
        { id: 2,
          //icon: 'telephone.svg',
          icon: 'placeholder.svg',
          heading: 'North Carolina (Plant 1)',
          description: '6844 NC 705 HWY, Robbins NC 27325'
        },
        {
          id: 3,
          //icon: 'email.svg',
          icon: 'placeholder.svg',
          heading: 'North Carolina (Plant 2)',
          description: '824 Hemp Ste Ext, Robbins NC 27325'
        }
      ]
    }
  }
}
</script>

<style lang="sass" scoped>
.contact
  &__heading
    h3
      text-align: left
      font-weight: 500
  &__description
    font-size: 14px
    text-align: start
  &__list, &__list--box
    display: flex
    align-items: center
  &__list
    margin-top: 2.2em
    &--box
      height: 65px
      width: 65px
      margin-right: 1.6em
      border: 1px solid $grey-5
      display: flex
      justify-content: center
      img
        width: 35px
    &--item
      flex: 1
      display: flex
      flex-direction: column
      align-items: flex-start
      .bold
        font-weight: 500
      h4, p
        margin: 0
      h4
        font-size: 1em
        font-weight: 300
        margin-bottom: 3px
      p
        font-size: 15px
  .gray
    color: #a7a7a7
</style>